@import "./assets/css/include/_variables_default.scss";
.header {
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: $height-header-mobile;
  //min-height: $height-header-desktop;
  position: fixed;
  z-index: 1030;
  background-color: $inverse-color;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.26);
  @include media-breakpoint-up(xl) {
    min-height: $height-header-desktop;
  }
  @include media-breakpoint-down(lg) {
    .wrapper-logo {
      min-height: $height-header-mobile;
      display: flex;
      align-items: center;
    }
  }

  .btn-header-phone__desktop {
    display: none;
    flex-direction: row;
    align-items: center;
    align-content: center;
    border: none;
    background: $secondary-color;
    color: $inverse-color;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 2.4rem;
    position: relative;
    transform: translate(0, 0);
    &:hover {
      color: $inverse-color;
      transform: translateY(-5px);
      box-shadow: 0 5px 10px #aaa;
    }
    i {
      font-size: 3.5rem;
      margin-right: 15px;
    }
    @include media-breakpoint-up(xl) {
      display: flex;
    }
  }
  .btn-header-phone__mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    border: none;
    background: $secondary-color;
    color: $inverse-color;
    padding: 10px;
    cursor: pointer;
    font-size: 2.4rem;
    position: relative;
    transform: translate(0, 0);
    &:hover {
      color: $inverse-color;
      transform: translateY(-5px);
      box-shadow: 0 5px 10px #aaa;
    }
    i {
      font-size: 3.5rem;
    }
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
  .header-phone {
    position: relative;
    //width: 50px;
    //height: 42px;
    //background-color: #FCB415;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    z-index: 1032;
    /*@include media-breakpoint-up(xl) {
      position: absolute;
      width: 64px;
      height: 58px;
    }*/
    /*.header-phone__icon {
      button, a {
        padding: 0;
        display: flex;
        align-items: center;
        i {
          font-size: 3rem;
          color: $inverse-color;
        }
      }
    }*/
    /*.header-phone__content {
      display: none;
    }*/
    /*&.header-phone_open {
      background-color: $inverse-color;
      right: 0;
      width: 463px;
      transform: translate(0,0);
      .header-phone__icon {
        display: none;
      }
      .header-phone__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 20px;
      }
    }*/
    /*.btn-phone-close {
      width: 34px;
      height: 34px;
      background-color: $tertiary-color;
      color: $inverse-color;
      &:hover {
        background-color: $secondary-color;
      }
    }*/

    /*.btn-phone-call {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .btn-phone-call-phone {
        font-family: $font-family-regular;
        font-size: 3.2rem;
        line-height: 1;
        color: $tertiary-color;
        &:hover {
          text-decoration: none;
        }
      }
      small {
        line-height: 1rem;
      }
    }*/
  }

  // Btn CallBack
  /*.btn-phone-callBack {
    font-family: $font-family-regular;
    font-size: 1.7rem;
    padding: 7px 20px;
    margin: 0 0 20px;
    color: $tertiary-color;
    @include media-breakpoint-up(xl) {
      margin: 0;
    }
  }*/

  /*.header-mon-compte {
    padding: 20px 0 15px;
    i {
      margin-right: 5px;
    }
    @include media-breakpoint-up(xl) {
      margin-left: auto;
      margin-right: 10rem;
      padding: 0;
    }
    button, a {
      font-family: $font-family-regular;
      font-size: 1.7rem;
      color: $tertiary-color;
      display: flex;
      align-items: center;
      margin: auto;
      &:hover {
        color: $primary-color;
        text-decoration: none;
      }
    }
    &.active {
      i {
        color: #FC6515;
      }
      margin-right: auto;
      @include media-breakpoint-up(xl) {
        margin-right: 8.5rem;
      }
    }
    .header-mon-compte__disconnect {
      font-size: 80%;
      margin-left: 2rem;
    }
  }*/
  /*#btnMonCompteConnected {
    @include media-breakpoint-down(xl) {
      margin: inherit;
    }
  }*/
  /*.header-mon-compte__dropdown {
    position: absolute;
    right: 0;
    top: $height-header-desktop;;
    padding: 20px 65px 20px 20px;
    background-color: $breadcrumb-bg;
    font-family: $font-family-regular;
    li:not(:last-child) {
      margin-bottom: 10px;
    }
    i {
      margin-right: 5px;
    }
    i.icon-infos-profile,
    i.icon-email-alert {
      font-size: 80%;
    }
    a {
      color: $tertiary-color;
      &:hover {
        text-decoration: none;
        color: $primary-color;
      }
    }
  }*/

  // Moteur HEADER Algolia
  /*.header-algoliaApp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 20px 0 0;
    margin: auto;
    @include media-breakpoint-up(sm) {
      width: 80%;
    }
    @include media-breakpoint-up(xl) {
      padding: 0;
      margin-left: 20px;
      margin-right: 0;
      justify-content: flex-start;align-items: center;
    }
    .btn-header-algoliaApp {
      background-color: transparent;
      font-size: 2.5rem;
      margin-right: 5px;
      margin-left: 0;
      display: flex;
      align-content: center;
      @include media-breakpoint-up(xl) {
        margin-left: 50px;
      }
    }
    #algoliaAppHeader {
      width: 0;
      border-radius: 0;
      height: 30px;
      border-bottom: 2px solid $tertiary-color;
      padding-left: 5px;
      visibility: hidden;
      opacity: 0;
      transition: width 0s, opacity 0.5s linear;
      z-index: 1031;
      &.show-element {
        visibility: visible;
        opacity: 1;
        width: 225px;
      }
      input {
        border-radius: 0;
        height: 30px;
        border-bottom: 2px solid $tertiary-color;
        padding-left: 5px;
        padding-right: 5px;
        background-color: transparent;
      }
      .react-autosuggest__suggestions-container--open {
        background-color: $inverse-color;
        z-index: 1031;
        max-height: 250px;
        margin-top: -290px;
        @include media-breakpoint-up(xl) {
          margin-top: 0;
        }
        overflow: auto;
        scrollbar-width: thin;
        scrollbar-color: $text-color #d7d7d7;
        &::-webkit-scrollbar{width:4px}
        &::-webkit-scrollbar-track{background:#d7d7d7;border-radius:4px}
        &::-webkit-scrollbar-thumb{background:$text-color;border-radius:5px}
        ul {
          list-style: none;
          margin: 0;
          padding: 0 10px;
          li {
            padding: 10px 0;
            line-height: 1;
            border-bottom: 1px solid $secondary-color;
            &:last-of-type {
              border-bottom: none;
            }
            &:hover {
              color: $secondary-color;
              cursor: pointer;
            }
          }
        }

      }
    }
  }*/
}

.wrapper-logo {
  min-height: $height-header-desktop;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 207px;
  //box-shadow: 0px 7px 5px -5px rgba(0, 0, 0, 0.26);
  @include media-breakpoint-up(xl) {
    width: 241px;
  }
}

.wrapper-comparator__desktop {
  display: none;
  @include media-breakpoint-up(xl) {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: $height-header-desktop;
    background-color: $inverse-color;
    border-bottom-left-radius: $border-radius-big;
    border-bottom-right-radius: $border-radius-big;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.26);
    .wrapper-comparator__links {
      margin-top: -3px;
      padding: 0 10px 5px;
      @media screen and (min-width: 1200px) and (max-width: 1250px) {
        padding: 0 5px 5px;
      }
      a {
        font-family: $font-family-bold;
        font-size: 1.5rem;
        letter-spacing: -0.05rem;
        &:hover {
          text-decoration: none;
        }
        i {
          font-size: 1.2rem;
        }
      }
    }
  }
}
.wrapper-comparator__degrade {
  width: 100%;
  height: 8px;
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,.2) 0%, rgba(255,255,255,1) 100%);
}

.wrapper-comparator__mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $inverse-color;
  .wrapper-comparator__links {
    text-align: center;
    padding: 3px 10px 5px;
    border-top: 1px solid $secondary-color;
    width: 90%;
    margin-top: 50px;
    a {
      font-family: $font-family-bold;
      font-size: 1.5rem;
      letter-spacing: -0.05rem;
      &:hover {
        text-decoration: none;
      }
      i {
        font-size: 1.2rem;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    display: none;
  }
}