@import "./assets/css/include/_variables_default.scss";
/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
//@import "root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
//@import "code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
//@import "custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
//@import "card";
//@import "breadcrumb";
//@import "pagination";
//@import "badge";
//@import "jumbotron";
@import "~bootstrap/scss/alert";
//@import "progress";
//@import "media";
//@import "list-group";
@import "~bootstrap/scss/close";
//@import "toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
//@import "popover";
//@import "carousel";
//@import "spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";
