@import "./assets/css/include/_variables_default.scss";
@font-face {
  font-family: 'icomoon';
  src: url('../../fonts/icomoon.ttf') format('truetype'),
  url('../../fonts/icomoon.woff') format('woff'),
  url('../../fonts/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-whatsapp:before {
  content: "\e943";
}
.icon-email:before {
  content: "\e941";
}
.icon-sms:before {
  content: "\e942";
}
.icon-comparison:before {
  content: "\e921";
}
.icon-coins:before {
  content: "\e944";
}
.icon-linkedin:before {
  content: "\e922";
}
.icon-compare-on:before {
  content: "\e951";
}
.icon-compare:before {
  content: "\e90c";
}
.icon-clock:before {
  content: "\e93e";
}
.icon-envelope:before {
  content: "\e93f";
}
.icon-fuel:before {
  content: "\e90a";
}
.icon-calendar:before {
  content: "\e905";
}
.icon-refresh:before {
  content: "\e93c";
}
.icon-hybrid-car:before {
  content: "\e933";
}
.icon-leaf:before {
  content: "\e934";
}
.icon-lighting:before {
  content: "\e935";
}
.icon-star-empty:before {
  content: "\e936";
}
.icon-van-cargo:before {
  content: "\e937";
}
.icon-truck:before {
  content: "\e938";
}
.icon-car:before {
  content: "\e939";
}
.icon-dashboard-light:before {
  content: "\e93a";
}
.icon-buy-back-light:before {
  content: "\e93b";
}
.icon-twitter:before {
  content: "\e92d";
}
.icon-instagram:before {
  content: "\e930";
}
.icon-youtube:before {
  content: "\e931";
}
.icon-facebook:before {
  content: "\e932";
}
.icon-crown:before {
  content: "\e927";
}
.icon-park-on:before {
  content: "\e914";
}
.icon-park-off:before {
  content: "\e91b";
}
.icon-new-car:before {
  content: "\e91c";
}
.icon-buy-back:before {
  content: "\e923";
}
.icon-search:before {
  content: "\e924";
}
.icon-user:before {
  content: "\e925";
}
.icon-contact:before {
  content: "\e926";
}
.icon-arrow-up:before {
  content: "\e900";
}
.icon-arrow-down:before {
  content: "\e901";
}
.icon-bell:before {
  content: "\e902";
}
.icon-book:before {
  content: "\e903";
}
.icon-calculator:before {
  content: "\e904";
}
.icon-clip:before {
  content: "\e906";
}
.icon-cookie:before {
  content: "\e907";
}
.icon-dashboard:before {
  content: "\e908";
}
.icon-diploma:before {
  content: "\e909";
}
.icon-fi-rr-form:before {
  content: "\e90b";
}
.icon-home:before {
  content: "\e90d";
}
.icon-print:before {
  content: "\e90e";
}
.icon-share:before {
  content: "\e90f";
}
.icon-smartphone:before {
  content: "\e910";
}
.icon-trash:before {
  content: "\e911";
}
.icon-garage:before {
  content: "\e912";
}
.icon-gas-station:before {
  content: "\e913";
}
.icon-hatchback:before {
  content: "\e915";
}
.icon-berline:before {
  content: "\e916";
}
.icon-break:before {
  content: "\e917";
}
.icon-4WD:before {
  content: "\e918";
}
.icon-space:before {
  content: "\e919";
}
.icon-ute:before {
  content: "\e91a";
}
.icon-dot-breadcrumb:before {
  content: "\e91d";
}
.icon-shifter:before {
  content: "\e91e";
}
.icon-circle-arrow-left:before {
  content: "\e91f";
}
.icon-circle-arrow-right:before {
  content: "\e920";
}
.icon-circle-arrow-up:before {
  content: "\e928";
}
.icon-star:before {
  content: "\e929";
}
.icon-cars:before {
  content: "\e92a";
}
.icon-co2:before {
  content: "\e92b";
}
.icon-ecology:before {
  content: "\e92c";
}
.icon-delivery:before {
  content: "\e92e";
}
.icon-arrow-left:before {
  content: "\e92f";
}
.icon-arrow-right:before {
  content: "\e93d";
}
.icon-close:before {
  content: "\e940";
}