@import "./assets/css/include/_variables_default.scss";
.navbar{
  flex: auto;
  justify-content: space-evenly;
  padding: 0;
  margin-top: 0;
  //background-color: $inverse-color;
  height: $height-header-mobile;
  @include media-breakpoint-up(xl) {
    height: $height-header-desktop;
    margin-top: 0;
  }
  .navbar-toggler{
    padding: 1rem 0 0;
    &:focus{
      outline: transparent;
    }
  }
  .navbar-brand {
    margin-right: 0;
    margin-left: 0;
    padding: 0;
    width: 127px;
    //height: 91px;
    text-align: center;
    @include media-breakpoint-up(xl) {
      width: 241px;
      height: 91px;
    }
  }
  .has-megamenu{
    position:static!important;
  }
  .megamenu{
    left:0;
    right:0;
    width:100%;
  }

  .navbar-collapse {
    flex-grow: initial;
    margin-left: 0;
    @include media-breakpoint-up(xl) {
      height: 100%;
    }
    @include media-breakpoint-down(xl) {
      background-color: $inverse-color;
    }
    /*@media screen and (min-width: 1671px) {
      margin-left: 50px;
    }*/
  }

  .navbar-nav {
    // GLOBAL
    .nav-item {
      padding-left: 0;
      .nav-link {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: $font-family-bold;
        font-size: 2rem;
        line-height: 1.1;
        color: $secondary-color;
        border-bottom: 5px solid transparent;
        @include transition(color 0.2s ease);
        &:hover {
          cursor: pointer;
        }
        i {
          font-size: 3rem;
          margin-right: 10px;
        }
        span {
          display: block;
        }
      }
    }
    // DESKTOP
    @media screen and (min-width: 1671px) {
      margin-left: 18px;
    }
    @include media-breakpoint-up(lg) {
      height: 100%;
      .nav-item {
        height: 100%;
        border-bottom: 0;
        .nav-link {
          height: 100%;
          display: flex;
          border-bottom: 5px solid transparent;
          @media screen and (min-width: 1200px) and (max-width: 1400px) {
            i {
              display: none;
            }
            padding: 5px 1.7rem 0;
            flex-direction: row;
            &:hover {
              border-bottom: 5px solid $secondary-color;
            }
          }
          @media screen and (min-width: 1400px) and (max-width: 1670px) {
            padding: 5px 1rem 0;
            flex-direction: row;
            &:hover {
              border-bottom: 5px solid $secondary-color;
            }
          }
          @media screen and (min-width: 1671px) {
            padding: 5px 3.2rem 0;
            flex-direction: row;
            &:hover {
              border-bottom: 5px solid $secondary-color;
            }
          }
        }
        .dropdown-toggle {
          &:after {
            display: none;
          }
        }
      }
    }

    // MOBILE
    @include media-breakpoint-down(lg) {
        .nav-item {
          margin-left: 0;
          width: 100%;
          .nav-link {
            height: 75px;
            margin: auto;
            width: 90%;
            padding: 15px 0;
            display: flex;
            flex-direction: row;
            justify-content: left;
            i {
              //margin-left: 10px;
              width: 45px;
              text-align: center;
            }
            span {
              display: inline-block;
            }
          }
          &:nth-last-of-type(2) {
            .nav-link {
              border-bottom: none;
            }
          }
          .dropdown-toggle {
            display: flex;
            justify-content: left;
            align-items: center;
            position: relative;
            &:after {
              font-family: icomoon;
              content: "\e92f";
              transform: rotate(90deg);
              border: none;
              display: inline-block;
              position: absolute;
              right: 0;
              margin-left: 1rem;
            }
          }
          &.show {
            box-shadow: 0 8px 8px rgba(0, 0, 0, 0.15);
            .dropdown-toggle {
              &:after {
                transform: rotate(-90deg);
              }
            }
          }
        }
    }

    // Sous-Menu
    .dropdown-menu {
      margin-top: 0;
      border: 0;
      padding: 0;
      background: transparent;
      @include media-breakpoint-up(xl) {
        //display: block;
        border: 0;
      }
    }
  }
}

// Sous-Menu MegaMenu
.megamenu__container {
  max-width: 1140px;
  margin: auto;
  padding: 20px 20px 20px;
  background: $inverse-color;
  //border-top: 1px solid $secondary-color;
  border-bottom-left-radius: $border-radius-base;
  border-bottom-right-radius: $border-radius-base;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.15);
  @include media-breakpoint-down(lg) {
    width: 90%;
    border-top: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none;
  }
  @include media-breakpoint-down(sm) {
    padding: 10px;
    width: 100%;
  }
  .col-border-right {
    border-right: none;
    @include media-breakpoint-only(md) {
      border-right: none;
      &:first-of-type {
        border-right: 1px solid $secondary-color;
      }
    }
    @include media-breakpoint-up(lg) {
      border-right: 1px solid $secondary-color;
    }
  }
  .megamenu__container--title {
    font-family: $font-family-bold;
    font-size: 2rem;
    color: $secondary-color;
    letter-spacing: -.3px;
    margin: 5px 0 10px 0;
    @include media-breakpoint-down(sm) {
      margin: 10px 0 10px 0;
    }
  }
  .block-btn-links {
    a {
      font-family: $font-family-semibold;
      margin: 10px 10px 0 0;
    }
  }
  .btn-box-default {
    font-family: $font-family-semibold;
    &:hover {
      .icon__2-roues {
          fill : #ffffff;
      }
    }
  }

  .icon__2-roues {
    height: 26px;
    fill: $secondary-color;
    transform-origin: center;
    transform: scaleX(-1);
    &:hover {
      fill : #ffffff;
    }
  }
}


// BOUTON BURGER ANIMATION
#nav-icon {
  width: 22px;
  height: 30px;
  //margin-top: 5px;
  margin-left: 15px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: $tertiary-color;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon span:nth-child(1) {
  top: 0px;
}

#nav-icon span:nth-child(2),#nav-icon span:nth-child(3) {
  top: 10px;
}

#nav-icon span:nth-child(4) {
  top: 20px;
}

#nav-icon.open span:nth-child(1) {
  top: 10px;
  width: 0%;
  left: 50%;
}

#nav-icon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon.open span:nth-child(4) {
  top: 10px;
  width: 0%;
  left: 50%;
}
