@import "./assets/css/include/_variables_default.scss";
ol.breadcrumb {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  font-family: $font-family-regular;
  font-size: 1.5rem;
  margin: 0;
  padding: 20px 0 20px 25%;
  line-height: 2rem;
  //background-color: $breadcrumb-bg;
  overflow-x: auto;
  overflow-y: hidden;
  @media screen and (max-width: 1200px) {
    padding: 20px 0 20px 20px;
    display: flex;
  }
  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    padding: 20px 0 20px 23%;
    display: flex;
  }
  @media screen and (min-width: 1400px) and (max-width: 1670px) {
    padding: 20px 0 20px 21%;
    display: flex;
  }
  @media screen and (min-width: 1671px) {
    padding: 20px 0 20px 19%;
    display: flex;
  }
/*  @include media-breakpoint-down(xl){
    display: none;
  }*/
  > li {
    display: flex;
    line-height: 12px;
    margin-bottom: 0;
    position: relative;
    &:before {
      font-family: icomoon;
      content: "\e90d";
      font-size: 1rem;
      color: $secondary-color;
    }
    &:first-of-type {
      padding-left: 0;
      span {
        padding-left: 0;
      }
    }
    a {
      @include transition(all 0.2s ease);
      color: $secondary-color;
      text-decoration: underline;
      span {
        @include transition(all 0.2s ease);
        color: $secondary-color;
        white-space: nowrap;
      }
    }
    span {
      @include transition(all 0.2s ease);
      color: $secondary-color;
      padding-left: 20px;
      display: block;
      width: max-content;
      &:hover {
        color: $primary-color;
      }
    }
    + li {
      padding-left: 6px;
      &:before {
        color: $fourthly-color;
        font-family: icomoon;
        content: "\e91d";
        font-size: .9rem;
        padding: 0;
        position: absolute;
        top: 1px;
      }
    }
    &:last-of-type {
      span {
        color: $secondary-color;
        font-family: $font-family-regular;
      }
    }
  }
  .separator {
    display: none;
  }
}