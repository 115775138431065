@import "./assets/css/include/_variables_default.scss";
.block-btn-links {
  .block-btn-links__link {
    display: inline-block;
    border: none;
    background-color: $bg-grey;
    font-size: 1.5rem;
    line-height: 1;
    color: $text-color;
    padding: 6px 15px;
    margin: 0 10px 10px 0;
    border-radius: $border-radius-small;
    font-family: $font-family-semibold;
    &:last-of-type {
      margin-right: 0;
    }
    &:hover {
      background-color: $secondary-color;
      color: $inverse-color;
      text-decoration: none;
    }
    &.active, &:active, &:active:hover, &:not(:disabled):not(.disabled):active, &:focus, &.disabled:hover, &.disabled:focus {
      background-color: $secondary-color;
      color: $inverse-color;
    }
    &.block-btn-links__link--primary {
      color: $primary-color;
      &:hover {
        background-color: $secondary-color;
        color: $inverse-color;
        text-decoration: none;
      }
    }
    &.block-btn-links__link--primary-inverse {
      background-color: $primary-color;
      color: $inverse-color;
      &:hover {
        background-color: $secondary-color;
        color: $inverse-color;
        text-decoration: none;
      }
    }
  }
  .block-btn-links__link--inverse {
    background-color: $inverse-color;
  }
}