@import "./assets/css/include/_variables_default.scss";
.footer__primary {
  background-color: $secondary-color;
  color: $inverse-color;
  padding: 40px 0 20px;
  h4 {
    color: $inverse-color;
    display: flex;
    align-items: center;
    i {
      margin-right: 10px;
    }
  }
  p {
    color: $inverse-color;
    a {
      color: $inverse-color;
      &:hover {
        color: $primary-color;
      }
    }
  }
  .footer__primary--rs {
    display: flex;
    margin-top: 10px;
    a {
      color: $inverse-color;
    }
  }
  hr {
    color: $inverse-color;
  }
  .footer__primary--horaires {
    font-size: 1.5rem;
    line-height: 2;
  }
  .mentions-legales {
    font-size: 1.4rem;
  }
  .block-newsletter {
    .form-group {
      margin-bottom: 0;
    }
  }
}
.footer__secondary {
  background-color: #000000;
  color: $inverse-color;
  font-size: 1.5rem;
  padding: 20px 0 10px;
  a, p {
    color: $inverse-color;
  }
  .footer__secondary--menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-family: $font-family-bold;
    font-size: 1.5rem;
    margin-left: auto;
    a {
      margin-right: 20px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}