@import "./assets/css/include/_variables_default.scss";
.toastify.on {
  opacity: .9;
  &.warning{
    background: $warning-color;
  }
  &.info{
    background: $info-color;
  }
  &.success{
    background: $success-color;
  }
  &.error, &.danger{
    background: $error-color;
  }
}

@media only screen and (max-width: 360px) {
  .toastify-right, .toastify-left {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    left: 0;
    right: 0;
    max-width: 60%;
  }
}
