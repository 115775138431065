@import "./assets/css/include/_variables_default.scss";
.btn {
  @include transition(all 0.2s ease);
  border-radius: $border-radius-base;
  font-family: $font-family-bold;
  font-size: 2rem;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  &:active {
    box-shadow: 0 0 0 0;
    outline: none;
  }
  &:focus {
    outline: none;
  }
}

.btn-primary {
  border: none;
  background: $primary-color;
  color: $inverse-color;
  padding: 15px 55px 15px 20px;
  &:after {
    background-color: $inverse-color;
    color: $primary-color;
  }
  &:hover {
    background: $primary-color;
  }
  &:active, &:active:hover, &:not(:disabled):not(.disabled):active, &:focus, &.disabled:hover, &.disabled:focus {
    background: darken( $primary-color, 20% );
  }
  &.disabled {
    background-color: darken( $primary-color, 20% );
  }
}
.btn-primary-small {
  border: none;
  background: $primary-color;
  color: $inverse-color;
  padding: 8px 45px 8px 15px;
  &:after {
    background-color: $inverse-color;
    color: $primary-color;
  }
  &:hover {
    color: $inverse-color;
    background: $primary-color;
  }
  &:active, &:active:hover, &:not(:disabled):not(.disabled):active, &:focus, &.disabled:hover, &.disabled:focus {
    background: darken( $primary-color, 20% );
  }
}

.btn-secondary {
  border: none;
  background: $secondary-color;
  color: $inverse-color;
  padding: 15px 55px 15px 20px;
  &:after {
    background-color: $inverse-color;
    color: $secondary-color;
  }
  &:hover {
    color: $inverse-color;
    background: $secondary-color;
  }
  &:active, &:active:hover, &:not(:disabled):not(.disabled):active, &:focus, &.disabled:hover, &.disabled:focus {
    background: darken( $secondary-color, 20% );
  }
  &.disabled {
    background-color: darken( $secondary-color, 20% );
  }
}
.btn-secondary-small {
  border: none;
  background: $secondary-color;
  color: $inverse-color;
  padding: 8px 45px 8px 15px;
  &:after {
    background-color: $inverse-color;
    color: $secondary-color;
  }
  &:hover {
    color: $inverse-color;
  }
  &:active, &:active:hover, &:not(:disabled):not(.disabled):active, &:focus, &.disabled:hover, &.disabled:focus {
    background: darken( $secondary-color, 20% );
  }
}

.btn-outline-primary {
  border: 5px solid $secondary-color;
  background: $inverse-color;
  color: $secondary-color;
  padding: 10px 50px 10px 15px;
  display: inline-block;
  line-height: 2.8rem;
  position: relative;
  transform: translate(0, 0);
  &:after {
    position: absolute;
    font-family: icomoon;
    content: "\e93d";
    width:  25px;
    height: 25px;
    top:   50%;
    transform: translate(0, -50%);
    right: 15px;
    border-radius: 50%;
    background-color: $secondary-color;
    color: $inverse-color;
    font-size: 1.3rem;
    line-height: 2.1;
    font-weight: bold;
  }
  &:hover {
    border: 5px solid $secondary-color;
    background: $inverse-color;
    color: $secondary-color;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
    transform: translateY(-5px);
  }
  &:active, &:active:hover, &:not(:disabled):not(.disabled):active, &:focus, &.disabled:hover, &.disabled:focus {
    background: $tertiary-color;
  }
}
.btn-outline-primary-small {
  border: 5px solid $secondary-color;
  background: $inverse-color;
  color: $secondary-color;
  padding: 0 40px 0 10px;
  display: inline-block;
  line-height: 3.7rem;
  position: relative;
  transform: translate(0, 0);
  &:after {
    position: absolute;
    font-family: icomoon;
    content: "\e93d";
    width:  25px;
    height: 25px;
    top:   6px;
    right: 10px;
    border-radius: 50%;
    background-color: $secondary-color;
    color: $inverse-color;
    font-size: 1.3rem;
    line-height: 2.1;
    font-weight: bold;
  }
  &:hover {
    border: 5px solid $secondary-color;
    background: $inverse-color;
    color: $secondary-color;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
    transform: translateY(-5px);
  }
  &:active, &:active:hover, &:not(:disabled):not(.disabled):active, &:focus, &.disabled:hover, &.disabled:focus {
    background: $tertiary-color;
  }
}

/**********************************/
/* BOUTONS - primary / secondary */
.btn-primary, .btn-secondary,
.btn-primary-small, .btn-secondary-small {
  cursor: pointer;
  display: inline-block;
  line-height: 2.8rem;
  position: relative;
  color: $inverse-color;
  transform: translate(0, 0);
  &:hover {
    color: $inverse-color;
    transform: translateY(-5px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
  }
}

.btn-primary:after, .btn-secondary:after, {
    position: absolute;
    font-family: icomoon;
    content: "\e93d";
    width:  25px;
    height: 25px;
    top:   50%;
    transform: translate(0, -50%);
    right: 20px;
    border-radius: 50%;
    font-size: 1.3rem;
    line-height: 2.1;
    font-weight: bold;
}

.btn-primary-small:after, .btn-secondary-small:after, {
  position: absolute;
  font-family: icomoon;
  content: "\e93d";
  width:  25px;
  height: 25px;
  top:   11px;
  right: 15px;
  border-radius: 50%;
  font-size: 1.3rem;
  line-height: 2.1;
  font-weight: bold;
}

.btn-picto {
  border: none;
  background: $bg-grey;
  border-radius: 20px;
  color: $text-color;
  transition :all 0.3s ease;
  text-align: center;
  width: 100%;
  transform: translate(0, 0);
  &:hover, &:focus {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transform: translateY(-5px);
    text-decoration: none;
    color: $inverse-color;
    background-color: $secondary-color;
    .btn-picto__content i, .btn-picto__content h3 {
      color: $inverse-color;
    }
  }
  .btn-picto__content {
    display: flex;
    flex-direction: column;
    i {
      font-size: 5rem;
      color: $secondary-color;
      margin: 0 0 10px;
    }
    .btn-picto__content--modele {
      font-family: $font-family-bold;
      font-size: 2rem;
      line-height: 1.2;
      margin: 0;
      color: $text-color;
    }
  }
  &.btn-picto__maker {
    &:hover, &:focus {
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
      cursor: pointer;
      transform: translateY(-5px);
      text-decoration: none;
      color: $text-color;
      background: $bg-grey;
      .btn-picto__content i, .btn-picto__content h3 {
        color: $text-color;
      }
    }
  }
  /*&:active, &:active:hover, &:not(:disabled):not(.disabled):active, &:focus, &.disabled:hover, &.disabled:focus {
    background: darken( $secondary-color, 20% );
  }*/
}

/* Btns divers */
.btn-link-primary {   color: $secondary-color;
  &:after { color: $primary-color; }
}
.btn-link-secondary { color: $secondary-color; }
.btn-link-inverse {   color: $inverse-color; }
.btn-link-primary, .btn-link-secondary, .btn-link-inverse {
  position: relative;
  font-size: 1.8rem;
  font-family: $font-family-bold;
  text-decoration: underline;
  &:after {
    font-family: icomoon;
    content: "\e920";
    padding-left: 10px;
    position: absolute;
  }
}

.btn-box-default {
  display: inline-block;
  background-color: $bg-grey;
  font-size: 1.5rem;
  line-height: 1;
  text-align: center;
  color: $text-color;
  padding: 5px 5px;
  width: 100%;
  border-radius: $border-radius-small;
  &:hover {
    background-color: $secondary-color;
    color: $inverse-color;
    text-decoration: none;
    i{
      color: $inverse-color;
    }
  }
  &:active, &:active:hover, &:not(:disabled):not(.disabled):active, &:focus, &.disabled:hover, &.disabled:focus {
    background-color: $secondary-color;
    color: $inverse-color;
  }
  i {
    font-size: 2rem;
    color: $secondary-color;
  }
  span {
    display: block;
  }
}

.btn-circle {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-circle-social {
  display: flex;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  i {
    font-size: 3.5rem;
    color: $inverse-color;
    &:hover {
      text-decoration: none;
      color: $primary-color;
    }
  }
}

.btn-go-to-top {
  position: relative;
  font-size: 1.4rem;
  font-family: $font-family-regular;
  text-decoration: underline;
  color: $inverse-color;
  white-space: nowrap;
  margin-right: 35px;
  &:after {
    font-family: icomoon;
    content: "\e920";
    font-size: 3.5rem;
    position: absolute;
    transform: rotate(-90deg);
    top: -12px;
    right: -35px;
    color: $primary-color;
  }
  &:hover {
    color: $primary-color;
    text-decoration: underline;
  }
}

.btn-more {
  font-size: 1.5rem;
  color: $text-color;
  text-decoration: underline;
  &:hover {
    color: $primary-color;
    &:after {
      color: $inverse-color;
    }
  }
  &:after {
    display: inline-block;
    transform: rotate(90deg);
    font-family: icomoon;
    font-size: 1.2rem;
    content: "\e93d";
    margin-left: 1.2rem;
    padding: 1px 0 0 0;
    //padding-bottom: 2px;
    width: 20px;
    height: 20px;
    background-color: $text-color;
    border-radius: $border-radius-big;
    color: $inverse-color;
  }
  &.active {
    &:after {
      display: inline-block;
      transform: rotate(-90deg);
    }
  }
}

.btn-compare, .btn-parking {
  color: #ddd;
  &:hover {
    color: $text-color;
  }
  &.active {
    color: $text-color;
  }
}

/* Btn SD "pills" pour trombi */
.nav-pills {
  .nav-link {
    border-radius: 10px;
    background-color: $bg-grey;
    color: $secondary-color;
    cursor: pointer;
    display: inline-block;
    line-height: 2.8rem;
    position: relative;
    transform: translate(0, 0);
    &:hover {
      color: $secondary-color;
      transform: translateY(-5px);
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
    }
    &.active {
      background-color: $secondary-color;
    }
    &.active:hover {
      background-color: $secondary-color;
      color: $inverse-color;
      transform: translate(0, 0);
      box-shadow: none;
      cursor: default;
    }
  }
}


  /* 2 boutons horizontaux */
.inline-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: -2rem;
  //margin-left: 0;
  @include media-breakpoint-up(md) {
    flex-direction: row;
    //margin-left: -2rem;
  }
  .inline-buttons__button {
    margin-top: 2rem;
    //margin-left: 0;
    @include media-breakpoint-up(md) {
      //margin-left: 2rem;
    }
  }
}


/* Bouton effet "Share" */
.share-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  div.button-top{
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  ul{
    cursor: pointer;
    list-style-type: none;
    position: relative;
    -webkit-transition: 0.5s;
    left: 0;
  }

  ul.active {
    -webkit-transition: 0.1s;
    //-webkit-transform: rotateX(10deg) rotateY(20deg) rotate3d(0,0,30px);
  }
  ul.active li{
    opacity: 1;
  }

  ul.active li:nth-child(1){
    -webkit-transform: translate(0px,220px) rotate(360deg);
    -webkit-transition-delay: 0s;
  }
  ul.active li:nth-child(2){
    -webkit-transform: translate(0, 176px) rotate(360deg);
    -webkit-transition-delay: 0.01s;
  }
  ul.active li:nth-child(3){
    -webkit-transform: translate(0, 132px) rotate(360deg);
    -webkit-transition-delay: 0.02s;
  }
  ul.active li:nth-child(4){
    -webkit-transform: translate(0, 88px) rotate(360deg);
    -webkit-transition-delay: 0.02s;
  }
  ul.active li:nth-child(5){
    -webkit-transform: translate(0, 44px) rotate(360deg);
    -webkit-transition-delay: 0.02s;
  }
  ul.active li:last-child{
    -webkit-transition-delay: 0.15s;
    //-webkit-transform: scale(1.2);
  }

  li{
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    padding-left: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    //left: 5px;
    //top: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1026;
    opacity: 0;
    -webkit-transition: 0.5s;
    transition: all 0.5s 0s ease-out;
    transition-timing-function: cubic-bezier(.22,.81,.01,.99);
    /*background-color: $primary-color;
    background-image: linear-gradient( to left, rgba(255,255,255,0), $sixthly-color);*/
    background-color: $secondary-color;
    //background-image: linear-gradient( to left, rgba(255,255,255,0), $sixthly-color);
    color: $inverse-color;
    &:hover, &:focus {
      /*background-color: $sixthly-color;
      background-image: linear-gradient( to left, rgba(255,255,255,0), $sixthly-color);*/
      background-color: $primary-color;
      //background-image: linear-gradient( to left, rgba(255,255,255,0), $sixthly-color);
      color: $inverse-color;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    }
    i {
      font-size: 2rem;
      color: $inverse-color;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      text-decoration: none;
    }
  }

  li:nth-child(1){
    -webkit-transition-delay: 0.2s;
  }
  li:nth-child(2){
    -webkit-transition-delay: 0.15s;
  }
  li:nth-child(3){
    -webkit-transition-delay: 0.1s;
  }
  li:last-child{
    width: 30px;
    height: 30px;
    //left: -5px;
    left: 0;
    //top: -8px;
    background-color: transparent;
    //background-image: linear-gradient( to left, rgba(255,255,255,0), $sixthly-color);
    color: $secondary-color;
    i {
      color: $secondary-color;
    }
    &:hover, &:focus {
      background-color: transparent;
     // background-image: linear-gradient( to left, rgba(255,255,255,0), $sixthly-color);
      color: $inverse-color;
      //box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
      box-shadow: none;
      i {
        color: $primary-color;
      }
    }
    -webkit-transition-delay: 0.1s;
    -webkit-transform: scale(1);
    z-index: 1026;
    opacity: 1;
  }
}