@import "./assets/css/include/_variables_default.scss";
@font-face {
  font-family: 'cairobold';
  src: url('../../fonts/cairo-bold-webfont.woff2') format('woff2'),
       url('../../fonts/cairo-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'cairosemibold';
  src: url('../../fonts/cairo-semibold-webfont.woff2') format('woff2'),
       url('../../fonts/cairo-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'cairoregular';
  src: url('../../fonts/cairo-regular-webfont.woff2') format('woff2'),
       url('../../fonts/cairo-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'cairolight';
  src: url('../../fonts/cairo-light-webfont.woff2') format('woff2'),
       url('../../fonts/cairo-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}