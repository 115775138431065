@import "./assets/css/include/_variables_default.scss";
html {
  font-size: 62.5%;
  height: 100%;
}

body {
  font-family: $font-family-regular;
  font-size: $font-size-base;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: $height-header-desktop;
  @include media-breakpoint-down(lg) {
    padding-top: $height-header-mobile;
  }
  @include media-breakpoint-down(md) {
    font-size: $font-size-mobile;
  }
}

.cd-main-content {
  flex: 1 0 auto;
}

h1 {
  font-family: $font-family-bold;
  font-size: 2.6rem;
  margin: 20px 0;
  @include media-breakpoint-up(lg) {
    font-size: 5rem;
    line-height: 1.2;
    margin: 30px 0;
  }
}

h2, .h2 {
  font-family: $font-family-bold;
  font-size: 2.2rem;
  line-height: 3rem;
  margin: 0 0 10px;
  color: $tertiary-color;
  @include media-breakpoint-up(lg) {
    font-size: 4.2rem;
    line-height: 5rem;
    margin: 0 0 20px;
  }
}

h3, .h3 {
  font-family: $font-family-semibold;
  font-size: 1.8rem;
  line-height: 2.5rem;
  margin: 10px 0 10px;
  @include media-breakpoint-up(lg) {
    font-size: 3rem;
    line-height: 4.5rem;
    margin: 10px 0 20px;
  }
  &.bolder {
    font-family: $font-family-bold;
  }
}

h4, .h4 {
  font-family: $font-family-regular;
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin: 10px 0 10px;
  @include media-breakpoint-up(lg) {
    font-size: 2.4rem;
    line-height: 2.5rem;
    margin: 20px 0 20px;
  }
  &.bolder {
    font-family: $font-family-bold;
  }
}

h5, .h5 {
  font-size: 2rem;
  line-height: 2.4rem;
  margin: 10px 0 20px;
  &.bolder {
    font-family: $font-family-bold;
  }
}

h1, h2, .h2, h3, .h3, h4, .h4 {
  color: $title-color;
}

hr {
  height: 1px;
  background: $inverse-color;
  border: none;
  margin-top: 15px;
  margin-bottom: 10px;
  /*&.inverse-style {
    background: transparent linear-gradient(90deg, #00000000 0%, #FFF 50%, #00000000 100%) 0% 0% no-repeat padding-box;
  }*/
}

p {
  color: $text-color;
  margin-bottom: 20px;
  strong {
    font-family: $font-family-bold;
  }
  a {
    color: $tertiary-color;
    text-decoration: underline;
  }
}

// LIENS TEXTUEL
a {
  transition :all 0.2s ease;
  color: $secondary-color;
  &:hover,
  &:focus {
    color: $primary-color;
    cursor: pointer;
  }
  &.link_text {
    font-family: $font-family-bold;
    color: $tertiary-color;
    line-height: 1;
    &:hover {
      color: $primary-color;
      text-decoration: none;
    }
  }
}

// LISTES
ul, ol {
  padding: 0;
}
ul {
  line-height: 28px;
  //padding-left: 0;
  //padding-right: 0;
  li {
    color: #231F20;
    line-height: 1.8rem;
    list-style-type: none;
    padding-left: 10px;
    font-family: $font-family-light;
    &.list-w-50 {
      @include media-breakpoint-up(lg) {
        width: 47%;
      }
    }
    &.list-w-25 {
      @include media-breakpoint-up(lg) {
        width: 22%;
      }
    }
    &.list-w-33 {
      @include media-breakpoint-up(md) {
        width: 47%;
      }
      @include media-breakpoint-up(lg) {
        width: 32%;
      }
    }
  }
}

.page-list{
  margin: 1rem;
  list-style: disc inside;
  line-height: 1.6rem;

  li {
    font-size: 1.6rem;
    list-style-type: disc;
    color: $tertiary-color;
    font-weight: bold;
  }

  .principal-pages{
    font-size: 1.8rem;
  }

  a{
    line-height: 2.4rem;
  }

  .vehicles-view-pages{
    font-size: 1.3rem;
  }
  .models-view-pages{
    font-size: 1.5rem;
  }
}

ol {
  counter-reset: li;
  line-height: 28px;
  margin-bottom: 30px;
  margin-top: 15px;
  padding-left: 0;
  padding-right: 0;
  font-family: $font-family-light;
  li {
    color: #231F20;
    line-height: 3rem;
    list-style: none;
    &:before {
      content: counter(li)'';
      counter-increment: li;
      display: inline-block;
      font-family: $font-family-bold;
      font-size: 1.6rem;
      font-weight: bold;
      margin-right: 5px;
      position: relative;
      color: $fourthly-color;
    }
  }
}

.list-bulled-style {
  li {
    padding-left: 15px;
    position: relative;
    width: 100%;
    padding-bottom: 5px;
    @include media-breakpoint-up(lg) {
      padding-bottom: 15px;
    }
    &:before {
      color: $fourthly-color;
      font-family: icomoon;
      content: "\e91d";
      font-size: .7rem;
      line-height: 1;
      padding: 0;
      transform: rotate(60deg);
      position: absolute;
      top: 5px;
      left: 0;
    }
    strong {
      font-family: $font-family-bold;
    }
    &.list-inline-item {
      display: inline-flex;
    }
  }

}

blockquote {
  padding: 0 0 0 3rem;
  margin: 2rem 0;
  position: relative;
  font-size: 1.7rem;
  line-height: 1.4;
  font-family: $font-family-bold;
  color: $secondary-color;
  &:before {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    color: $fourthly-color;
    width: 10px;
    background-color: $fourthly-color;
    border-top-left-radius: $border-radius-base;
    border-bottom-right-radius: $border-radius-base;
    height: 100%;
  }
  @include media-breakpoint-up(lg) {
    font-size: 2.4rem;
  }
  div {
    font-family: $font-family-regular;
  }
}

// SHOW / HIDE
.show-element {
  display: block!important;
}
.show-inline-element {
  display: inline-block!important;
}
.hide-element {
  display: none!important;
}

// Flash Messages
#flashMessage {
  display: none;
}

// UTILITAIRES
.push-to-bottom { flex: 1 1 auto; }
.cursor-pointer   {cursor: pointer;}
.no-pointer-event { pointer-events: none;}
.ss-titre {
  font-size: 1.8rem;
  line-height: 1.2;
  @include media-breakpoint-up(lg) {
    font-size: 2.4rem;
  }
}
.icon-confirmation {
  font-size: 8rem;
  color: $secondary-color;
  margin-bottom: 10px;
  @include media-breakpoint-up(lg) {
    font-size: 14rem;
    margin-bottom: 30px;
  }
}

// overide chrome outline on focus element
:focus {
  outline: none;
}

// Labels / étiquettes - Item et fiche vehicule
.label {
  position: absolute;
  padding: 4px 10px;
  font-size: 1.5rem;
  &.label-reserve {
    background-color: #000;
    right: 10px;
    top: 10px;
    font-family: $font-family-bold;
    color: $inverse-color;
    border-radius: $border-radius-base;
    // Liste
    &__item {}
    // Fiche vehicule
    &__view { top: 30px; z-index: 1025; }
  }
  &.label-tag {
    background-color: $inverse-color;
    left: 10px;
    bottom: 10px;
    font-family: $font-family-regular;
    border-radius: $border-radius-base;
    // Liste
    &__item {}
    // Fiche vehicule
    &__view { top: 0; }
  }
}
// Autocomplete gmap
.pac-container.pac-logo {
  z-index: 9999;
}
div#tarteaucitronRoot.tarteaucitronBeforeVisible:before {
  z-index: 1030 !important;
}