@import "./assets/css/include/_variables_default.scss";
.block-newsletter {
  width: 100%;
  display: flex;
  input {
    height: 50px;
    border-radius: $border-radius-small;
    border-bottom: 5px solid #FCCB2D;
    &:focus {
      border-bottom: 5px solid $primary-color;
    }
  }
  .btn-submit {
    background-color: #FCCB2D;
    border: none;
    color: $text-color;
    padding: 10px 15px;
    margin-left: 20px;
    margin-right: 10px;
    &:hover {
      background-color: $primary-color;
    }
  }
}