@import "./assets/css/include/_variables_default.scss";
.form-control,
.form-control[readonly] {
  background-color: $inverse-color;
  /*border-color: $border-color;
  border-width: $border-width;*/
  border-radius: $border-radius-small;
  border: 1px solid $secondary-color;
  //border-bottom: 5px solid $secondary-color;
  color: $secondary-color;
  font-family: $font-family-bold;
  font-size: 1.5rem;
  height: 60px;
  line-height: normal;
  padding: 5px 10px;
  &:focus {
    border: 1px solid $primary-color;
    //border-bottom: 5px solid $primary-color;
    color: $secondary-color;
    //box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  }
  &::-moz-placeholder {
    color: $placeholder-color;
    font-family: $font-family-regular;
    font-weight: lighter;
  }
  &::-webkit-input-placeholder {
    color: $placeholder-color;
    font-family: $font-family-regular;
    font-weight: lighter;
  }
  &:-ms-input-placeholder  {
    color: $placeholder-color;
    font-family: $font-family-regular;
    font-weight: lighter;
  }
  &.disabled, &:disabled {
    opacity: .8;
    &:hover {
      cursor: not-allowed;
    }
  }
}

.form-group {
  margin-bottom: 3rem;
}

/*div.form-control {
  background-color: transparent;
  border-color: transparent;
  padding-left: 0;
  padding-right: 0;
  label {
    font-size: 1.5rem;
    font-family: $font-family-text;
    margin-bottom: 1rem;
  }
  .custom-file {
    font-size: 1.8rem;
    font-family: $font-family-text;
    color: $inverse-color;
    @include media-breakpoint-down(xs) {
      font-size: 1.2rem;
    }
  }
}*/

label, legend {
  font-family: $font-family-bold;
  font-size: 1.8rem;
  cursor: pointer;
  color: $secondary-color;
  margin-bottom: 1rem;
  @include media-breakpoint-down(xs) {
    font-size: 1.5rem;
  }
}

/*****************/
/* Btn CHECKBOX */
input[type="checkbox"] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: $inverse-color;
  border: 1px solid $secondary-color;
  border-radius: 8px;
  width: 25px;
  height: 25px;
  margin-top: 0;
  &:checked{
    border: 1px solid $secondary-color;
    background-color: $secondary-color;
    box-shadow:inset 0px 0px 0px 4px $inverse-color;
  }
  &:hover {
    border: 1px solid $secondary-color;
  }
}
.content-checkbox, .form-group-check {
  input[type="radio"] {
    margin-right: 0;
  }
  label {
    font-size: 1.5rem;
    font-family: $font-family-regular;
    margin: 2px 0 0 20px;
    color: $text-color;
    a {
      color: $primary-color;
      text-decoration: underline;
      &:hover {
        color: $secondary-color;
      }
    }
  }
  .form-check {
    display: flex;
    align-items: center;
  }
}

.form-group-check {
  display: flex;
  flex-wrap: wrap;
  .form-check {
    padding-top: 10px;
    padding-bottom: 20px;
    flex: 1 0 50%;
    @include media-breakpoint-up(md) {
      flex: 1 0 25%;
    }
  }
}

/* TEXTAREA */
textarea.form-control {
  height: 230px;
  resize: none;
  margin: 0;
  padding: 2rem;
}
select.form-control {
  appearance: none;
  cursor: pointer;

}
.form-group--caret-down {
  position: relative;
}
.form-group--caret-down:after {
  content: "\e90b";
  font-family: icomoon;
  color: $tertiary-color;
  font-size: 1.4rem;
  position: absolute;
  right: 28px;
  bottom: 25px;
}
/**************/
/* Btn RADIO */
input[type="radio"] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 1px solid $secondary-color;
  border-radius: $border-radius-big;
  width: 25px;
  height: 25px;
  margin-right: 5px;
  &:checked{
    background-color: $secondary-color;
    box-shadow:inset 0px 0px 0px 4px $inverse-color;
  }
  /*&:hover {
    border: 1px solid $tertiary-color;
  }*/
}
.content-radio {
  legend {
    margin-bottom: 10px;
  }
  input[type="radio"] {
    margin-right: 0;
    &:hover{
      cursor: pointer;
    }
  }
  div {
    display: flex;
    align-items: center;
    label {
      padding: 0 30px 0 20px;
      font-family: $font-family-regular;
      font-size: 1.5rem;
      color: $text-color;
    }
  }
  .form-check-input {
    margin-top: 0;
  }
}

/****************/
/* SELECT MENU */
.select-css {
  display: block;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: .6em 1.4em .5em 1.3em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMjQiIGhlaWdodD0iMTAyNCIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCI+Cjx0aXRsZT48L3RpdGxlPgo8ZyBpZD0iaWNvbW9vbi1pZ25vcmUiPgo8L2c+CjxwYXRoIGQ9Ik0zOC40IDMwMi45MzNjMC0xNy4wNjcgNC4yNjctMzQuMTMzIDE3LjA2Ny00Ni45MzMgMjUuNi0yNS42IDY0LTI1LjYgODkuNiAwbDM3MS4yIDM3MS4yIDM3MS4yLTM2Ni45MzNjMjUuNi0yNS42IDY0LTI1LjYgODkuNiAwczI1LjYgNjQgMCA4OS42bC00NjAuOCA0NjAuOC00NjAuOC00NjAuOGMtMTIuOC0xMi44LTE3LjA2Ny0yOS44NjctMTcuMDY3LTQ2LjkzM3oiPjwvcGF0aD4KPC9zdmc+Cg==");
  background-repeat: no-repeat, repeat;
  background-position: right 1.5em top 50%, 0 0;
  background-size: 1em auto, 100%;
  &:hover {
    cursor: pointer;
  }
}
.select-css::-ms-expand {
  display: none;
}

.select-css:focus {
  border-color: $border-color;
  color: $text-color;
  outline: none;
}
.select-css option {
  font-weight:normal;
}

// Mention obligatoire
.mention-obligatoire {
  font-size: 1.4rem;
  font-style: normal;
  line-height: 1;
  color: $placeholder-color;
}

/* TESTS BOOSTRAP VALIDATOR */
.was-validated .form-check-input:invalid,
.was-validated .custom-control-input:invalid {
  border-color: #dc3545;
}
.was-validated .custom-control-input:valid~.custom-control-label, /* pour la case à cocher */
.was-validated .form-check-input:valid ~ .form-check-label  /* pour le label de la case à cocher quand validé par le Submit */
{
  color: $text-color;
}

/* STRIPE FORM */
/*
.StripeElement {
  height: 69px;
  padding: 20px 10px 5px 20px!important;
  border-radius: 5px!important;
  border: none!important;
  border-bottom: 5px solid #51A3CC!important;
  box-shadow: none!important;
}
.alert {
  border-radius: 0;
}
#card-errors{
  color: $error-color;
}*/

