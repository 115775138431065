@import "./assets/css/include/_variables_default.scss";
.section-page {
  padding: 25px 0;
  @include media-breakpoint-up(lg) {
    padding: 30px 0 70px;
  }
}

.bg-grey {
  background-color: #f7f7f7;
}

.bg-dark-degrade {
  background: linear-gradient(to right, $secondary-color, #001B53);
  color: $inverse-color;
  h2, p {
    color: $inverse-color;
  }
}